@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
  font-family: "Switzer";
  src:
    url("/src/assets/fonts/Switzer-Medium.ttf") format("truetype"),
    url("/src/assets/fonts/Switzer-Semibold.ttf") format("truetype"),
  
      url("/src/assets/fonts/Switzer-Regular.ttf") format("truetype"),
      url("/src/assets/fonts/Switzer-light.ttf") format("truetype");


  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: "Switzer";
  src: url("/src/assets/fonts/Switzer-Light.ttf") format("truetype");
  font-weight: 300; /* Light weight */
  font-style: normal;
}

@font-face {
  font-family: "Switzer";
  src: url("/src/assets/fonts/Switzer-Regular.ttf") format("truetype");
  font-weight: 400; /* Regular weight */
  font-style: normal;
}

@font-face {
  font-family: "Switzer";
  src: url("/src/assets/fonts/Switzer-Medium.ttf") format("truetype");
  font-weight: 500; /* Medium weight */
  font-style: normal;
}

@font-face {
  font-family: "Switzer";
  src: url("/src/assets/fonts/Switzer-Semibold.ttf") format("truetype");
  font-weight: 600; /* Semibold weight */
  font-style: normal;
}

/* Add the bold version if needed */
@font-face {
  font-family: "Switzer";
  src: url("/src/assets/fonts/Switzer-Bold.ttf") format("truetype");
  font-weight: 700; /* Bold weight */
  font-style: normal;
}

body {
  font-family: "Switzer";
  outline: none;
}

::-webkit-scrollbar {
  width: 0;
  display: none;
}
::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}
::-webkit-scrollbar-thumb {
  background-color: rgb(39, 37, 86);

  /* border-radius: 50px; */
}

@media (max-width: 840px) {
  ::-webkit-scrollbar {
    display: none;
  }
}
.toastBody {
  color: #10171d; /* #10171D */
  font-size: 1.3rem !important;
}

@keyframes grow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.animate-image {
  animation: grow 3s infinite;
}

.box-shadow {
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.form-box_shadow {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

/* input[type="checkbox"]:checked + label svg, */
input[type="radio"]:checked + label div {
  display: block;
}

/* input[type="checkbox"]:checked + label, */
input[type="radio"]:checked + label {
  background-color: #23a863;
  border-color: #23a863;
}
input[type="checkbox"]:checked {
  color: #23a863;
  border-color: #23a863;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthXs.MuiDialog-paperFullWidth.css-twia2z-MuiPaper-root-MuiDialog-paper {
  border-radius: 15px !important;
}
